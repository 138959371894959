import { ReactComponent as Logo } from "./bank360.svg";
import { ReactComponent as MenuIcon } from "./menu.svg";
import { ReactComponent as Trend } from "./trend.svg";
import { ReactComponent as SafetyIcon } from "./safety.svg";
import { ReactComponent as Centralized } from "./centralized.svg";
import { ReactComponent as GuaranteeIcon } from "./guarantee.svg";
import { ReactComponent as ArrowGo } from "./arrow-go.svg";
import { ReactComponent as ArrowLeft } from "./arrow-left.svg";
import { ReactComponent as ArrowRight } from "./arrow-right.svg";
import { ReactComponent as WhiteLogoIcon } from "./bank360-white.svg";
import { ReactComponent as LogoIcon } from "./bank360.svg";
import { ReactComponent as MoneyIcon } from "./money.svg";
import { ReactComponent as TopQuadrantIcon } from "./top-quadrant.svg";
import { ReactComponent as BottomQuadrantIcon } from "./bottom-quadrant.svg";
import { ReactComponent as EyeOpenIcon } from "./eye-open.svg";
import { ReactComponent as EyeCloseIcon } from "./eye-close.svg";
import { ReactComponent as ChevronLeftIcon } from "./ChevronLeft.svg";
import { ReactComponent as ChevronRightIcon } from "./ChevronRight.svg";

import { ReactComponent as DashboardIcon } from "./Dashboard.svg";
import { ReactComponent as AnalyticsIcon } from "./Analytics.svg";
import { ReactComponent as BankStatementsIcon } from "./BankStatements.svg";
import { ReactComponent as DailyUpdatesIcon } from "./DailyUpdates.svg";
import { ReactComponent as SettingsIcon } from "./Settings.svg";
import { ReactComponent as LogoutIcon } from "./Logout.svg";
import { ReactComponent as HomeIcon } from "./Home.svg";
import { ReactComponent as FinancialTrendsIcon } from "./FinancialTrends.svg";
import { ReactComponent as RefreshIcon } from "./Refresh.svg";
import { ReactComponent as ChevronDownIcon } from "./ChevronDown.svg";
import { ReactComponent as SimilarIcon } from "./similar.svg";
import { ReactComponent as CopyIcon } from "./copy.svg";
import { ReactComponent as InflowIcon } from "./inflow.svg";
import { ReactComponent as OutflowIcon } from "./outflow.svg";
import { ReactComponent as ArrowDownIcon } from "./arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "./arrow-up.svg";
import { ReactComponent as CalendarIcon } from "./calendar.svg";
import { ReactComponent as SearchIcon } from "./search.svg";

import { ReactComponent as GreenUpIcon } from "./green-up.svg";
import { ReactComponent as RedDownIcon } from "./red-down.svg";
import { ReactComponent as OptionsIcon } from "./options.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as CheckIcon } from "./check.svg";

import {ReactComponent as Facebook} from "./facebook.svg";
import {ReactComponent as Instagram} from "./instagram.svg";
import {ReactComponent as Linkedin} from "./linkedin.svg";
import {ReactComponent as X} from "./X.svg";
import PlayStore from "./playstore.png";
import AppStore from "./appstore.png";

import AccessLogo from "./BankLogos/access-icon.png";
import GtBankLogo from "./BankLogos/Gtbank-icon.png";
import WemaLogo from "./BankLogos/wema-icon.png";

export const Icons = {
  Logo,
  MenuIcon,
  Trend,
  SafetyIcon,
  GuaranteeIcon,
  Centralized,
  ArrowGo,
  ArrowLeft,
  ArrowRight,

  WhiteLogoIcon,
  LogoIcon,
  MoneyIcon,
  TopQuadrantIcon,
  BottomQuadrantIcon,
  EyeOpenIcon,
  EyeCloseIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
  CloseIcon,
  CheckIcon,

  DashboardIcon,
  AnalyticsIcon,
  BankStatementsIcon,
  DailyUpdatesIcon,
  SettingsIcon,
  LogoutIcon,
  HomeIcon,
  FinancialTrendsIcon,
  RefreshIcon,
  MenuIcon,
  InflowIcon,
  OutflowIcon,

  Facebook, 
  Linkedin,
  Instagram,
  X,
  PlayStore,
  AppStore,

  ChevronDownIcon,
  SimilarIcon,
  CopyIcon,
  BankLogos: {
    AccessLogo,
    GtBankLogo,
    WemaLogo,
  },
  SearchIcon,

  GreenUpIcon,
  RedDownIcon,
  OptionsIcon,
};
