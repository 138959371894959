import React from 'react';
import { WaitlistStyles } from './style';
import hero_2 from "../../assets/images/hero_2.png";


const {
  Container, 
} = WaitlistStyles;

const Waitlist = () => {
  return (
    <Container>
      <div className="waitlist-section">
        <div className="waitlist-content">
          <h2>Join Our Waitlist</h2>
          <p>Receive exclusive updates, early access to features, 
          and special offers tailored just for you.</p>
          <div className="waitlist-form">
            <input type="email" placeholder="Email address" />
            <button>Join</button>
          </div>
        </div>
        <div class="waitlist-image">
          <img src={hero_2} alt="Waitlist Illustration" />
        </div>
      </div>
    </Container>
  );
};

export default Waitlist;
