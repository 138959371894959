import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Component } from "../../frontend-components";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { signUpValidation } from "./Signup.validation";
import { SignupStyle } from "./style";

const getPasswordStrength = (password: string) => {
  if (!password) return "";
  if (password.length < 8) return "Weak";
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const hasSpecialChar = /[@$!%*?&#]/.test(password);

  if (hasUppercase && hasNumber && hasSpecialChar) return "Strong";
  if ((hasUppercase && hasNumber) || (hasUppercase && hasSpecialChar)) return "Moderate";
  return "Weak";
};

const Signup: FC = () => {
  const [passwordStrength, setPasswordStrength] = useState("");
  const [height, setHeight] = useState("85%"); // Initial height
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // To redirect the user after successful signup

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_no: "",
      org_name: "",
      password: "",
      confirm_password: "",
    },
    onSubmit: (values) => {
      setLoading(true);

      // Mock signup logic (replace with actual API request later)
      setTimeout(() => {
        values.phone_no = "+" + values.phone_no;
        localStorage.setItem("tempAuth", JSON.stringify(values));
        setLoading(false);
        navigate("/home"); // Navigate to a "Welcome" or dashboard page after signup
      }, 1000);
    },
    validationSchema: signUpValidation,
  });

  // Update height based on validation errors after fields have been touched
  useEffect(() => {
    const hasErrors = Object.keys(formik.errors).length > 0;
    const hasTouched = Object.keys(formik.touched).length > 0;

    if (hasErrors && hasTouched) {
      setHeight("95%");
    } else {
      setHeight("85%");
    }
  }, [formik.errors, formik.touched]);

  // Monitor password input for strength updates
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    formik.handleChange(e); // Keep Formik's state updated
    setPasswordStrength(getPasswordStrength(password)); // Update password strength
  };

  return (
    <SignupStyle>
      <AuthLayout>
        <section
          className="form-container"
          style={{ height }} // Dynamically set height
        >
          <h2>Sign Up</h2>
          <p className="sub-head">Monitor your cash flow easily</p>
          <form action="" className="auth-form" onSubmit={formik.handleSubmit}>
            <Component.AuthInput
              placeholder="First name"
              type="text"
              keyValue="first_name"
              formik={formik}
            />
            <Component.AuthInput
              placeholder="Last name"
              type="text"
              keyValue="last_name"
              formik={formik}
            />
            <Component.AuthInput
              placeholder="Email address"
              type="email"
              keyValue="email"
              formik={formik}
            />
            <Component.AuthInput
              placeholder="Phone number"
              type="tel"
              keyValue="phone_no"
              formik={formik}
            />
            <Component.AuthInput
              placeholder="Organisation name"
              type="text"
              keyValue="org_name"
              formik={formik}
            />
            <Component.AuthInput
              placeholder="Password"
              type="password"
              keyValue="password"
              formik={formik}
              onChange={handlePasswordChange}
              showPasswordStrength={true}
            />
            <Component.AuthInput
              placeholder="Confirm password"
              type="password"
              keyValue="confirm_password"
              formik={formik}
            />

            <button type="submit" className="auth-button" disabled={loading}>
              {loading ? (
                <>
                  <Component.Loader noPadding={true} />
                </>
              ) : (
                "Sign up"
              )}
            </button>

            <p className="extra">
              Already have an account? <Link to={"/login"}>Log in</Link>
            </p>
          </form>
        </section>
      </AuthLayout>
    </SignupStyle>
  );
};

export default Signup;
