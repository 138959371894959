import styled from "styled-components";

const Wrapper = styled.section`
  padding-top: 16px !important;
  padding-bottom: 8px !important;
  background-color: var(--accent-white);
  text-align: center;
  margin: 0;

  .bottom-text {
    margin-top: 1rem;
    margin-bottom: -1rem;
    text-align: center;
    font-size: 1.2rem;
    height: contain;

    @media screen and (max-width: 904px) {
      margin-top: 5rem;
    }
  }

  .marquee-content {
    height: 100px;
    overflow: hidden;
    display: flex;
    gap: 15px;
    margin-top: 2rem;
  }

  .marquee-content img {
    margin: 0 2rem;
    overflow: hidden;
    gap: 50px;
  }

  .access, .moniepoint, .opay, 
  .jaiz, .piggyvest, .wema, .uba, 
  .ecobank, .zenith-bank, .fbn, 
  .fcmb, .stanbic, .gtbank, .first-bank, .kuda{
    max-width: 100px;
    max-height: 20px; 
  }

  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }

  section {
    display: flex;
    align-items: flex-start; /* Changed from 'left' to 'flex-start' */
    justify-content: space-around;
    margin-top: 186px;

    @media screen and (max-width: 830px) {
      flex-direction: column; 
      margin-top: 8rem; 
    }

    @media screen and (min-width: 720px) {
      margin-top: 96px;
      padding-bottom: 32px !important;
    }

    @media screen and (min-width: 1024px) {
      margin-top: 126px;
    }

    @media screen and (min-width: 1100px) {
      margin-top: 15rem;
      margin-bottom: 0rem;
      margin-left: 0;
    }

    aside {
      width: 100%;
      margin: auto;

      @media screen and (max-width: 720px) {
        width: 100%; /* Full width on smaller screens */
        margin-left: 0; /* Reset margin */
      }
    }

    .hero img{
      margin: 0px auto;
    }

    .hero {
      margin-left: 0rem;
      width: 90%;
      margin-top: -4rem;

      @media screen and (max-width: 1300px) {
        width: 100%; 
        margin: 0px auto;
        margin-top: -1rem;
      }
      
      @media screen and (max-width: 820px) {
        width: 100%; 
        margin: 0px auto;
        margin-top: 1rem;
      }
    }
  }
`;

const HeaderText = styled.h1`
  color: var(--text-main);
  max-width: 550px;
  font-size: 2.4rem;
  line-height: 2.88rem;
  font-weight: 600;

  @media screen and (max-width: 185px) {
      font-size: 1.1rem;
  }

  @media screen and (min-width: 250px) {
    font-size: 3rem;
    line-height: 3.84rem;
    text-align: center;
  }

  @media screen and (min-width: 500px) {
    font-size: 4rem;
    line-height: 3.84rem;
    text-align: center;
    margin: 0px auto;
    margin-top: 3rem;

  }

  @media screen and (min-width: 830px) {
    font-size: 4rem;
    line-height: 4.8rem;
    text-align: left;
  }

  @media screen and (min-width: 1024px) {
    font-size: 4.8rem;
    line-height: 5.76rem;
  }

  @media screen and (min-width: 1200px) {
    line-height: 6rem;
    max-width: 700px;
    text-align: left;
    font-size: 6.5rem;
    letter-spacing: 0.3px;
    margin: 0px;
  }
`;

const SubHeaderText = styled.p`
  margin-top: 10px;
  color: #24365d;
  max-width: 300px;
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 2rem;
  text-align: center;

  @media screen and (min-width: 250px) {
    line-height: 2.5rem;
    font-size: 1.7rem;
    text-align: center;
    margin: 0px auto;
    padding-top: 1rem;
    max-width: 450px;
  }

  @media screen and (min-width: 375px) {
    line-height: 2.5rem;
    font-size: 1.7rem;
    text-align: center;
    margin: 0px auto;
    padding-top: 1rem;
    max-width: 450px;
  }

  @media screen and (min-width: 830px) {
    font-size: 1.45rem;
    line-height: 2.5rem;
    max-width: 330px;
    margin: 0px;
    text-align: left;
  }

  @media screen and (min-width: 904px) {
    font-size: 1.5rem;
    line-height: 2.5rem;
    max-width: 350px;
    text-align: left;
    margin: 0px;
  }

  @media screen and (min-width: 1000px) {
    font-size: 1.5rem;
    line-height: 2.5rem;
    max-width: 370px;
    margin: 0px;

  }

  @media screen and (min-width: 1024px) {
    font-size: 1.5rem;
    line-height: 2.5rem;
    max-width: 400px;
    text-align: left;
    margin: 0px;

  }

  @media screen and (min-width: 1185px) {
    font-size: 1.8rem;
    line-height: 3.2rem;
    text-align: left;
    max-width: 500px;
    margin: 0px;
  }

  @media screen and (min-width: 1300px) {
    font-size: 1.8rem;
    line-height: 3.2rem;
    text-align: left;
    max-width: 600px;
    margin: 0px;

  }
`;

const FieldSet = styled.fieldset`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  
  a {
    text-decoration: none;

    @media screen and (max-width: 820px) {
      margin: 0px auto;
    }
  }

  button {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 21px;
    padding: 12px 18px;
    border-radius: 8px;
    width: 100%;
  }

  .register-btn {
    color: var(--main-white);
    background: var(--button-bg, #01a0c6);
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.2;
    padding: 20px 30px;
    border-radius: 3.5rem;
    margin: 1rem auto;
    width: max-content;
    text-align: center;
    transition: all 0.3s ease-in-out;

  &:hover {
    background-color: var(--theme-hover);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    outline: 2px solid var(--theme-hover);
    outline-offset: 4px;
  }

  @media screen and (max-width: 1200px) {
    padding: 18px 26px;
    font-size: 1.6rem;
  }

  @media screen and (max-width: 1000px) {
    padding: 16px 24px;
  }

  @media screen and (max-width: 900px) {
    padding: 14px 20px;
    font-size: 1.4rem;
    border-radius: 4rem;
  }

  @media screen and (max-width: 260px) {
    padding: 5px 1px;
    font-size: 0.6rem;
    border-radius: 2rem;
    width: 50px;
  }
    }
}


  .login-btn {
    color: var(--main-white);
    background: var(--theme);
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 21px;
    padding: 12px 18px;
    border-radius: 8px;

    &:hover {
      background-color: var(--theme-hover);
    }
  }

  .contact-btn {
    color: var(--text-main);
    border: 1px solid #dadae7;
    margin-top: 16px;

    &:hover {
      border-color: var(--text-main);
    }
  }

  @media screen and (min-width: 420px) {
    flex-direction: row;

    button {
      width: auto;
    }

    .contact-btn {
      margin-top: 0;
      margin-left: 16px;
    }
  }
`;

export const HomeBannerStyles = {
  Wrapper,
  HeaderText,
  SubHeaderText,
  FieldSet,
};