import styled from "styled-components";

export const EmptyGraphContainer = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px;

    p {
        margin-top: 16px;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #94a6b3;
        max-width: 252px;
    }
`;
