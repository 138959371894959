import AuthInput from "./AuthInput/AuthInput";
import OTPInput from "./OTPInput/OTPInput";
import Avatar from "./Avatar/Avatar";
import Loader from "./Loader/Loader";
import BankList from "./BankList/BankList";
import RowedTable from "./Table/Table";
import Select from "./Select/Select";
import Checkbox from "./Checkbox/Checkbox";
import BankStatementsTable from "./BankStatementsTable/Table";
import AnalyticsTable from "./AnalyticsTable/Table";
import Input from "./Input/Input";
import AbridgedBankList from "./BankList/AbridgedBankList";
import HelpWidget from "./HelpWidget/HelpWidget";

export const Component = {
  AuthInput,
  OTPInput,
  Avatar,
  Loader,
  BankList,
  RowedTable,
  BankStatementsTable,
  Select,
  Checkbox,
  AnalyticsTable,
  Input,
  AbridgedBankList,
  HelpWidget,
};
