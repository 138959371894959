import React, { FC, useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { Link } from "react-router-dom";
import { Icons } from "../../assets/icons";
import "react-phone-input-2/lib/style.css";

import {
  PASSWORD_STRENGTH_BAR_COLORS,
  PASSWORD_STRENGTH_SCORE_WORDS,
} from "../../utils/constants";
import { IAuthInputProps } from "./AuthInput.interface";
import { AuthInputStyle } from "./AuthInput.style";
import PhoneInput from "react-phone-input-2";

const AuthInput: FC<IAuthInputProps> = ({
  placeholder,
  type,
  keyValue,
  formik,
  underLink,
  showPasswordStrength,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [showStrengthBar, setShowStrengthBar] = useState(false); // Track if strength bar should be displayed

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!showStrengthBar && event.target.value) {
      setShowStrengthBar(true); // Show the strength bar only once user starts typing
    }
    formik.handleChange(event); // Update Formik's state
  };

  return (
    <AuthInputStyle>
      <div className="input-container">
        {type !== "tel" && (
          <input
            id={keyValue}
            name={keyValue}
            type={
              type === "password" ? (passwordShown ? "text" : "password") : type
            }
            value={formik.values[keyValue]}
            placeholder={placeholder}
            onChange={handleInputChange} // Use the custom handler
          />
        )}
        {type === "tel" && (
          <PhoneInput
            country={"ng"}
            value={formik.values[keyValue]}
            onChange={(phone) => formik.setFieldValue(keyValue, phone)}
            placeholder={placeholder}
            inputClass="phone-input"
            buttonClass="phone-input-btn"
            autoFormat={false}
          />
        )}
        {type === "password" && (
          <button
            className="eye"
            onClick={() => {
              setPasswordShown((prev) => !prev);
            }}
            type="button"
          >
            {!passwordShown ? <Icons.EyeOpenIcon /> : <Icons.EyeCloseIcon />}
          </button>
        )}
      </div>

      {/* Show the password strength bar only if enabled */}
      {showPasswordStrength && showStrengthBar && (
        <PasswordStrengthBar
          password={formik.values[keyValue]}
          barColors={PASSWORD_STRENGTH_BAR_COLORS}
          scoreWords={PASSWORD_STRENGTH_SCORE_WORDS}
          minLength={8}
        />
      )}

      {/* Show validation error */}
      {formik.errors[keyValue] && formik.touched[keyValue] && (
        <small className="error">{formik.errors[keyValue]}</small>
      )}

      {/* Render underlink if provided */}
      {underLink?.text && (
        <Link to={underLink.link} className="underlink">
          {underLink.text}
        </Link>
      )}
    </AuthInputStyle>
  );
};

export default AuthInput;
