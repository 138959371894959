import { useFormik } from "formik";
import { FC, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Component } from "../../frontend-components";
import AuthLayout from "../../layouts/AuthLayout/AuthLayout";
import { loginValidation } from "./Login.validation";
import { LoginStyle } from "./style";


const Login: FC = () => {
    const navigate = useNavigate(); // Navigate to another page after login
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        onSubmit: (values) => {
            setLoading(true);

            // Mock login logic (replace with actual API request later)
            setTimeout(() => {
                localStorage.setItem("tempAuth", JSON.stringify(values));
                setLoading(false);
                navigate("/home"); // Navigate to the home/dashboard page
            }, 1000);
        },
        validationSchema: loginValidation,
    });

    return (
        <LoginStyle>
            <AuthLayout>
                <section className="form-container">
                    <h2>Sign In</h2>
                    <p className="sub-head">Monitor your cash flow easily</p>
                    <form
                        action=""
                        className="auth-form"
                        onSubmit={formik.handleSubmit}
                    >
                        <Component.AuthInput
                            placeholder="Email address"
                            type="email"
                            keyValue="email"
                            formik={formik}
                        />

                        <Component.AuthInput
                            placeholder="Password"
                            type="password"
                            keyValue="password"
                            formik={formik}
                            underLink={{
                                link: "/forgot-password",
                                text: "Forgot Password?",
                            }}
                        />

                        <button
                            type="submit"
                            className="auth-button"
                            disabled={loading}
                        >
                            {loading ? (
                                <>
                                    <Component.Loader noPadding={true} />
                                </>
                            ) : (
                                "Sign in"
                            )}
                        </button>
                        <p className="extra">
                            New here?{" "}
                            <Link to={"/signup"}>Sign Up</Link>
                        </p>
                    </form>
                </section>
            </AuthLayout>
        </LoginStyle>
    );
};

export default Login;
